/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';

export const replaceHydrateFunction = () => {
  const queryClient = new QueryClient();
  return (element, container, callback) => {
    ReactDOM.hydrate(
      <QueryClientProvider client={queryClient}>
        <Hydrate>{element}</Hydrate>
      </QueryClientProvider>,
      container,
      callback,
    );
  };
};
